.nav-header {
    position: relative;
    z-index: 41;
}

.sticky-wrapper {
    transition: 0.4s ease-in-out;

    &.sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: $white-color;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        animation: stickyAni 0.4s ease-in-out;
    }
}

@keyframes stickyAni {
    0% {
        transform: translate3d(0, -40px, 0) scaleY(0.8);
        opacity: 0.7;
    }

    100% {
        transform: translate3d(0, 0, 0) scaleY(1);
        opacity: 1;
    }
}

.main-menu {
    a {
        display: block;
        position: relative;
        font-weight: 400;
        font-size: 16px;
        color: $title-color;
        font-family: $title-font;

        &:hover {
            color: $theme-color;
        }
    }

    >ul {
        >li {
            margin: 0 27px;

            >a {
                padding: 36px 0;

                &:hover {
                    color: $title-color;
                }
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            position: relative;

            &.menu-item-has-children {
                >a {
                    &:after {
                        content: "\f078";
                        position: relative;
                        font-family: $icon-font;
                        margin-left: 4px;
                        font-weight: 700;
                        top: 0;
                        font-size: 12px;
                    }
                }
            }

            &:last-child {
                margin-right: 0 !important;
            }

            &:first-child {
                margin-left: 0 !important;
            }

            &:hover {
                >ul.sub-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: scaleY(1);
                    z-index: 9;
                }
            }
        }
    }

    ul.sub-menu {
        position: absolute;
        text-align: left;
        top: 100%;
        left: 0;
        background-color: $white-color;
        visibility: hidden;
        min-width: 190px;
        width: max-content;
        padding: 7px;
        left: -14px;
        opacity: 0;
        z-index: -1;
        border: 0;
        box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
        border-radius: 0;
        transform: scaleY(0);
        transform-origin: top center;
        transition: all 0.4s ease 0s;

        a {
            font-size: 16px;
            line-height: 30px;
        }
    }

    ul.sub-menu {
        padding: 18px 20px 18px 18px;
        left: -27px;

        li {
            display: block;
            margin: 0 0;
            padding: 0px 9px;

            &.menu-item-has-children {
                >a:after {
                    content: "\f105";
                    float: right;
                    top: 1px;
                }
            }

            a {
                position: relative;
                padding-left: 23px;
                text-transform: capitalize;

                &:before {
                    content: "\f105";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    font-family: $icon-font;
                    width: 11px;
                    height: 11px;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                    font-size: 1em;
                    line-height: 1;
                    color: $title-color;
                    font-weight: 500;
                }
            }

            ul.sub-menu {
                left: 100%;
                right: auto;
                top: 0;
                margin: 0 0;
                margin-left: 20px;

                li {
                    ul {
                        left: 100%;
                        right: auto;
                    }
                }
            }
        }
    }
}

/****header-top*****/
.main-menu {
    a {
        display: block;
        position: relative;
        font-weight: 400;
        font-family: $title-font;
        font-size: 16px;
        color: $title-color;

        &:hover {
            color: $theme-color;
        }
    }

    >ul {
        >li {
            margin: 0 11px;

            >a {
                padding: 31.5px 0;

                &:hover {
                    color: $theme-color;
                }
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            position: relative;

            &.menu-item-has-children {
                >a {
                    &:after {
                        content: "\f107";
                        position: relative;
                        font-family: $icon-font;
                        margin-left: 5px;
                        font-weight: 600;
                        top: 0;
                        font-size: 16px;
                        display: inline-block;
                        transition: 0.4s;
                        transform: rotate(0deg);
                        color: $theme-color;
                    }
                }

                &:hover {
                    >a {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &:last-child {
                margin-right: 0 !important;
            }

            &:first-child {
                margin-left: 0 !important;
            }

            &:hover {
                >ul.sub-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: scaleY(1);
                    z-index: 9;
                }
            }
        }
    }

    ul.sub-menu {
        position: absolute;
        text-align: left;
        top: 100%;
        left: 0;
        background-color: $white-color;
        visibility: hidden;
        min-width: 190px;
        width: max-content;
        left: -14px;
        opacity: 0;
        z-index: -1;
        border: 0;
        box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
        border-radius: 0;
        transform: scaleY(0);
        transform-origin: top center;
        transition: all 0.4s ease 0s;
        padding: 18px 20px 18px 18px;
        left: -27px;

        a {
            font-size: 16px;
            line-height: 30px;

            span {
                font-size: 12px;
                padding: 0px 5px;
                margin-left: 4px;
                background-color: $theme-color;
                color: $white-color;
                padding: 2px 5px;
                border-radius: 4px;
                position: relative;
                top: -1px;
            }
        }

        li {
            display: block;
            margin: 0 0;
            padding: 0px 9px;

            &.menu-item-has-children {
                >a:after {
                    float: right;
                    top: 1px;
                    font-size: 18px;
                    transform: none;
                    transition: 0.4s;
                }

                &:hover {
                    >a:after {
                        opacity: 0;
                        margin-right: 5px;
                    }
                }
            }

            a {
                position: relative;
                padding-left: 0px;
                text-transform: capitalize;

                &:before {
                    content: "\f105";
                    position: absolute;
                    top: 5px;
                    left: 10px;
                    font-family: $icon-font;
                    width: 11px;
                    height: 11px;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                    font-size: 18px;
                    line-height: 1;
                    color: $theme-color;
                    font-weight: 700;
                    opacity: 0;
                    transition: 0.4s;
                }

                &:hover {
                    padding-left: 15px;

                    &:before {
                        opacity: 1;
                        left: 0;
                    }
                }
            }

            ul.sub-menu {
                left: 100%;
                right: auto;
                top: 0;
                margin: 0 0;
                margin-left: 20px;

                li {
                    ul {
                        left: 100%;
                        right: auto;
                    }
                }
            }
        }
    }
}

.simple-icon {
    border: none;
    background-color: transparent;
    color: $title-color;
    padding: 0;
    font-size: 22px;
    position: relative;

    .badge {
        padding: 0.35em 0.57em;
        font-size: 10px;
        font-weight: 500;
        top: -6px;
        right: 0;
        left: auto;
    }

    &:has(.badge) {
        padding-right: 12px;
    }
}

.header-button {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 20px 25px;

    .th-btn {
        margin-left: 10px;
    }
}

.social-links {
    display: inline-flex;
    gap: 40px;
    align-items: center;
    line-height: normal;

    a {
        font-size: 16px;
        display: inline-block;
        color: $body-color;
        margin: 0;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $theme-color;
        }
    }
}

.header-logo {
    padding-top: 15px;
    padding-bottom: 15px;
}

.header-links {
    >ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    li {
        display: inline-block;
        position: relative;
        font-size: 18px;
        font-weight: 400;

        &:not(:last-child) {
            margin: 0 30px 0 0;
        }

        >i {
            margin-right: 10px;
            color: $theme-color;
        }
    }

    li,
    span,
    p,
    a {
        color: $white-color;
        font-family: $title-font;
    }

    a:hover {
        color: $theme-color2;
    }

    b,
    strong {
        font-weight: 600;
        margin-right: 6px;
    }
}

.header-grid-info {
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    li {
        display: inline-flex;
        gap: 15px;

        &:not(:last-child) {
            border-right: 1px solid $border-color;
            padding-right: 30px;
            margin-right: 30px;
        }

        .icon {
            font-size: 32px;
            color: $theme-color;
        }

        .header-grid-info-details {
            p {
                margin-bottom: 2px;
                font-family: $title-font;
                font-size: 18px;
                font-weight: 400;
                margin-top: -0.4em;
            }

            h6 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: -0.3em;
            }
        }
    }
}

/* Header 1 ---------------------------------- */
.header-layout1 {
    .header-wrapper {
        display: flex;
        gap: 39px;

        @media(max-width: 1399px) {
            gap: 15px;
        }

        .simple-icon {
            &:not(:last-child) {
                border-right: 1px solid rgba(25, 53, 45, 0.4);
                height: 46px;
                padding-right: 25px;
                margin-right: 0px;
            }


        }

    }

    .social-links {
        position: relative;
        gap: 10px;

        a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            font-size: 18px;
            text-align: center;
            background-color: #EBF3EE;
            transition: all 0.4s ease-in-out;

            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }
        }
    }

    .header-navbar-logo {
        margin-top: 0;
        padding: 15px 0;
        padding-left: 60px;
        position: absolute;
        z-index: 3;
    }

    .menu-area {
        position: relative;

        .logo-bg {
            position: absolute;
            height: calc(100% + 80px);
            width: 14.2%;
            top: -50px;
            left: 0;
            background: $theme-color;
            border-radius: 0 0 5px 0;
        }
    }

    .navbar-right-desc {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;

        .navbar-right-desc-details {
            .title {
                font-size: 18px;
                font-weight: 400;
                display: block;
                margin-bottom: -2px;
            }

            .link {
                font-size: 20px;
                font-weight: 700;
                font-family: $title-font;
            }
        }

        i {
            margin-right: 10px;
            transform: rotate(-45deg);
        }

        a {
            color: $title-color;

            &:hover {
                color: $theme-color;
            }
        }
    }
}

@media (max-width: 1700px) {
    .header-layout1 .header-navbar-logo {
        padding-left: 35px;
    }

    .header-layout1 .header-top {
        .header-links {
            padding-left: 160px;
        }
    }

    .header-layout1 .menu-area {
        .main-menu {
            padding-left: 160px;
        }

        .logo-bg {
            width: 16%;
        }
    }
}

@include xxl {
    .header-layout1 .header-top .header-links {
        padding-left: 0;
    }

    .header-layout1 .menu-area .main-menu {
        padding-left: 200px;
    }

    .header-layout1 .menu-area .logo-bg {
        height: calc(100% + 10px);
        width: 17%;
        top: 0;
    }

    .header-layout1 .header-navbar-logo {
        margin-top: 13px;
    }

    .header-layout1 .sticky-wrapper.sticky .header-navbar-logo {
        margin-top: 15px;
    }
}

@include ml {
    .header-layout1 .menu-area .main-menu {
        padding-left: 160px;
    }

    .header-layout1 .header-navbar-logo {
        padding-left: 30px;
    }
}

@include xl {
    .header-layout1 .menu-area .main-menu {
        padding-left: 230px;
    }

    .header-layout1 .menu-area .logo-bg {
        width: 20%;
    }

    .main-menu a {
        font-size: 16px;
    }
}

@include lg {
    .header-layout1 .menu-area .logo-bg {
        display: none;
    }

    .header-layout1 .header-navbar-logo {
        display: none;
    }

    .header-layout1 .menu-area .main-menu {
        padding-left: 0;
    }
}

@include md {
    .header-layout1 .sticky-wrapper {
        padding: 0px 20px 0;
    }

    .header-layout1 .header-navbar-logo {
        margin-top: 0;

        img {
            max-width: none;
        }
    }

    .header-layout1 .sticky-wrapper.sticky {
        top: 0;
    }

    .header-layout1 .social-links {
        padding-right: 20px;
        padding-left: 20px;
    }

    .header-layout1 .social-links:after {
        left: 0;
    }
}

@include xs {
    .header-layout1 .sticky-wrapper {
        padding: 0;
    }
}



/* Header 2 ---------------------------------- */
.header-layout2 { 

    .sticky-wrapper {
        background-color: $smoke-color; 

        &.sticky {
            .header-navbar-logo {
                margin-top: 25px;
            }
        }
    }
    .global-btn {
        padding: 22px 30px;
    }

    .header-top {
        background: $title-color; 
        padding: 12px 0;
    }
    .main-menu > ul > li > a {
        padding: 35.5px 0;
    }

    .social-links {
        position: relative;
        padding-right: 20px;

        &:after {
            content: '';
            position: absolute;
            left: -20px;
            right: 0;
            bottom: -14px;
            top: -15px;
            background: rgba(255, 255, 255, 0.1);
            z-index: -1;
        }
    }

    .header-navbar-logo {
        margin-top: 0;
        padding: 15px 0;
        padding-left: 60px;
        position: absolute;
        z-index: 3;
    }
}

/* Header 3 ---------------------------------- */
.header-layout3 {
    .header-top {
        padding: 12px 0;
        background: $theme-color;
        position: relative;
        z-index: 1;
    }
    .header-links li > i {
        color: $white-color;
    }
    .header-top-bg-shape {
        background: $white-color;
        position: absolute;
        height: 100%;
        width: 26%;
        top: 0;
        right: 0;
        z-index: -1;
        clip-path: polygon(18px 0%, 100% 0%, 100% 100%, 0% 100%);
        &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 99%;
            background: $theme-color2;
            right: 0;
            top: 0;
            clip-path: polygon(18px 0%, 100% 0%, 100% 100%, 0% 100%);
        }
        @include hd {
            width: 32%;
        }
    }
    .social-links {
        gap: 10px;
        a {
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;
            border: 1px solid $theme-color;
            text-align: center;
            color: $theme-color;
            &:hover {
                background: $theme-color;
                color: $white-color;
            }
        }
    }
    .header-button {
        gap: 20px 50px;
        .simple-icon {
            color: $theme-color;
            &:hover {
                color: $theme-color2;
            }
        }
    }
    .menu-area {
        .form-select {
            height: 48px;
            line-height: 48px;
        }

        .form-group {
            >i {
                right: 25px;
                top: 18px;
            }
        }
    }

    .header-sticky-logo {
        display: none;
    }

    .sticky-wrapper.sticky {
        .header-sticky-none {
            display: none !important;
        }

        .header-sticky-logo {
            display: block;
        }
    }

    @include lg {
        .sticky-wrapper.sticky {
            .header-lg-sticky-none {
                display: none !important;
            }
        }
    }

    @include md {
        .header-search-wrap .search-form {
            .form-select {
                display: none;
            }
        }

        .sticky-wrapper.sticky .header-sticky-logo {
            display: none;
        }
    }
}
/* Header 4 ---------------------------------- */
.header-layout4 {
    .header-top {
        background: #121212;
        padding: 19px 0;
    }
    .header-links li > i {
        color: $white-color;
    }
    .social-links {
        gap: 10px;
        a {
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;
            border: 1px solid $theme-color;
            text-align: center;
            font-size: 16px;
            &:hover {
                background: $theme-color2;
                color: $title-color;
                border: 1px solid $theme-color2;
            }
        }
    }
    .sticky-wrapper {
        background: $theme-color;
    }
    .main-menu > ul > li > a {
        color: $white-color;
        &:after {
            color: $white-color;
        }
        &:hover {
            color: $theme-color2;
            &:after {
                color: $theme-color2;
            }
        }        
    }
    .simple-icon {
        color: $white-color;
        &:not(:last-child) {
            border-right: 1px solid rgba($color: #ffffff, $alpha: 0.4);
            height: 46px;
            padding-right: 25px;
            margin-right: 0px;
        }
        img {
            filter: brightness(9);
        }
        &:hover {
            color: $theme-color2;
        }
    }
    .icon-btn {
        background: transparent;
        border: 1px solid $white-color;
        line-height: 43px;
        &:hover {
            background: $white-color;
            color: $theme-color;
        }
    }
}

/* Header 5 ---------------------------------- */
.header-layout5 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .header-top {
        background: #243F37;
        padding: 22px 20px;
        border-radius: 0 0 5px 5px;
    }
    .header-links li > i {
        color: $theme-color2;
    }
    .main-menu > ul > li > a {
        color: $white-color;
        &:after {
            color: $white-color;
        }
        &:hover {
            color: $theme-color2;
            &:after {
                color: $theme-color2;
            }
        }        
    }
    .simple-icon {
        color: $white-color;
        &:hover {
            color: $theme-color2;
        }
    }
    .header-button {
        gap: 20px 48px;
    }
    .sticky-wrapper.sticky {
        background: $title-color;
    }
}